import { FunctionComponent, ReactPortal, useEffect } from 'react';
import { createPortal } from 'react-dom';

type PopupPortalProps = {
  portalElementId?: string;
  children: any;
};

const PopupPortal: FunctionComponent<PopupPortalProps> = ({
  portalElementId,
  children,
}): ReactPortal => {
  const mountContainer = document.body;
  let popupContainer = document.getElementById(portalElementId || 'menu-popup-portal');

  if (!popupContainer) {
    popupContainer = document.createElement('div');
    popupContainer.id = portalElementId || 'menu-popup-portal';
    popupContainer.classList.add('portal-container');
  }

  useEffect(() => {
    mountContainer?.appendChild(popupContainer as HTMLElement);
    return () => {
      mountContainer?.removeChild(popupContainer as HTMLElement);
    };
  }, [mountContainer, popupContainer]);

  return createPortal(children, popupContainer);
};

export default PopupPortal;
