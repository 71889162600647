import Router from './router/Router';
import { Helmet } from 'react-helmet';
import mainImg from './assets/images/auth/present_image_1.webp';
import multimediaImg from './assets/images/auth/present_image_2.webp';
import presentImage from './assets/images/auth/present_image_3.webp';
import logo from './assets/images/siteLogo/logo.svg';
import RemoteAlert from './components/common/AlertModal/RemoteAlert';
// import { pdfjs } from 'react-pdf';
// import ThemeSwitchButton from './components/common/ThemeSwitchButton/ThemeSwitchButton';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

function App() {
  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={mainImg} />
        <link rel="preload" as="image" href={multimediaImg} />
        <link rel="preload" as="image" href={presentImage} />
        <link rel="preload" as="image" href={logo} />
      </Helmet>
      <RemoteAlert />
      <Router />
      {/*<ThemeSwitchButton />*/}
    </>
  );
}

export default App;
