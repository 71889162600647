import { KeyValueModel } from '../models/global';
import { PositionsByCategoryModel } from '../models';

export const initQuestionPositions = (
  pos: KeyValueModel<PositionsByCategoryModel>,
): PositionsByCategoryModel[] => {
  const positionsByCategories: PositionsByCategoryModel[] = Object.values(pos || {}).sort(
    (a, b) => a.position - b.position,
  );
  if (positionsByCategories?.length) {
    const pos = positionsByCategories.map(c => {
      if (c.sub_categories) {
        c.sub_categories = Object.values(c.sub_categories || {}).sort(
          (a, b) => a.position - b.position,
        );
      }
      return c;
    });
    return pos;
  }
  return [];
};
