import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { store } from './store/store';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import App from './App';
import theme from './theme';
import ThemeController from './components/common/ThemeController/ThemeController';
import Snackbar from './components/common/Snackbar/Snackbar';

if (process.env.REACT_APP_ENV === 'prod') {
  const tagManagerArgs = {
    gtmId: 'GTM-MQQKTBK',
  };
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <Snackbar />
      <ThemeController />
    </ThemeProvider>
  </Provider>,
);
