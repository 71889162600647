import { lazy } from 'react';
//
const SignIn = lazy(
  () =>
    import(/* webpackChunkName: "SignIn" */ /* webpackPreload: true */ './SignIn/SignIn'),
);
const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPassword" */ /* webpackPreload: true */ './ForgotPassword/ForgotPassword'
    ),
);
const SignUpStep1 = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpStep1" */ /* webpackPreload: true */ './SignUp/SignUpStep1'
    ),
);
const SignUpStep2 = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpStep2" */ /* webpackPreload: true */ './SignUp/SignUpStep2'
    ),
);
const SignUpStep3 = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpStep3" */ /* webpackPreload: true */ './SignUp/SignUpStep3/SignUpStep3Container'
    ),
);
const SignUpThx = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpThx" */ /* webpackPreload: true */ './SignUp/SignUpThx'
    ),
);

const SelectFreePracticeExam = lazy(
  () => import('./FreePracticeExam/SelectFreePracticeExam'),
);
const FreePracticeExam = lazy(() => import('./FreePracticeExam/FreePracticeExam'));
const CourseList = lazy(() => import('./CourseList/CourseList'));
const CourseScoreList = lazy(() => import('./CourseScoreList/CourseScoreList'));
const ExtraCredits = lazy(() => import('./ExtraCredits/ExtraCredits'));
const MP3 = lazy(() => import('./mp3/MP3'));
const CourseScoreByCategories = lazy(
  () => import('./CourseScoreByCategories/CourseScoreByCategories'),
);

const FreePracticeExamResult = lazy(
  () => import('./FreePracticeExam/FreePracticeExamResult'),
);
const SetForgotPassword = lazy(() => import('./SetForgotPassword/SetForgotPassword'));
const MyProfile = lazy(() => import('./MyProfile/MyProfile'));
const AddCourseList = lazy(() => import('./AddCourse/AddCourseList'));
const AddCoursePayment = lazy(
  () => import('./AddCourse/AddCoursePayment/AddCoursePaymentContainer'),
);
const AddCourseThx = lazy(() => import('./AddCourse/AddCourseThx'));
const ErrorPage = lazy(() => import('./AddCourse/ErrorPage'));
const CourseAssignment = lazy(() => import('./CourseAssignment/CourseAssignment'));
const CourseQuizResult = lazy(() => import('./CourseAssignment/CourseQuizResult'));
const SimulationExam = lazy(() => import('./SimulationExam/SimulationExam'));
const QToolCreateCustomExam = lazy(
  () => import('./QToolCreateCustomExam/QToolCreateCustomExam'),
);
const QToolExam = lazy(() => import('./QToolExam/QToolExam'));
const QToolSimExam = lazy(() => import('./QToolSimulationExam/QToolSimulationExam'));
const QToolExamReport = lazy(() => import('./QToolExamReport/QToolExamReport'));
const QToolPrint = lazy(() => import('./QToolPrint/QToolPrint'));

export {
  SignIn,
  ForgotPassword,
  SetForgotPassword,
  SignUpStep1,
  SignUpStep2,
  SignUpStep3,
  SelectFreePracticeExam,
  CourseList,
  CourseScoreList,
  CourseScoreByCategories,
  CourseAssignment,
  FreePracticeExam,
  FreePracticeExamResult,
  CourseQuizResult,
  SignUpThx,
  MyProfile,
  AddCourseList,
  AddCoursePayment,
  AddCourseThx,
  ErrorPage,
  SimulationExam,
  ExtraCredits,
  MP3,
  QToolCreateCustomExam,
  QToolExamReport,
  QToolExam,
  QToolSimExam,
  QToolPrint,
};
