import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { setTheme } from '../../../store/user/userSlice';

const ThemeController = () => {
  const defaultTheme = 'light';
  const dispatch = useAppDispatch();
  const themeFromRedux = useAppSelector(state => state.user.theme);

  useEffect(() => {
    let theme: string;
    const themeFromLocalStorage = localStorage.getItem('theme');

    if (themeFromRedux) {
      theme = themeFromRedux;

      if (themeFromRedux !== themeFromLocalStorage) {
        localStorage.setItem('theme', themeFromRedux);
      }
    } else if (themeFromLocalStorage) {
      dispatch(setTheme(themeFromLocalStorage));
      theme = themeFromLocalStorage;
    } else {
      dispatch(setTheme(defaultTheme));
      localStorage.setItem('theme', defaultTheme);
      theme = defaultTheme;
    }

    document.documentElement.className = theme;
  }, [themeFromRedux]);

  return null;
};

export default ThemeController;
