import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { getProfile } from '../../../store/user/userThunk';
import BackdropSpinner from '../../controls/BackdropSpinner/BackdropSpinner';

interface IInitApp {
  children: React.ReactNode;
}

const InternalAppWrapper: FC<IInitApp> = ({ children }: IInitApp) => {
  const userInfo = useAppSelector(state => state.user.user);
  const dispatch = useAppDispatch();

  const fetchUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => fetchUserData(), []);

  if (!userInfo) {
    return <BackdropSpinner />;
  }

  return <>{children}</>;
};

export default InternalAppWrapper;
