import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignUpStep1Form, SignUpStep2Form } from '../../models';
import {
  signOut,
  calcSignUpCourses,
  fetchSignUpOptions,
  signIn,
  signUp,
  getProfile,
} from './userThunk';
import { UserState } from './userRedux.model';

const initialState: UserState = {
  theme: null,
  user: null,
  access_token: localStorage.getItem('access_token'),
  signUp: {
    calcData: null,
    options: null,
    step1Form: {
      industry: 0,
      state: 0,
      selectedCoursesIds: [],
      checkedLicense: false,
    },
    step2Form: {
      first_name: '',
      last_name: '',
      state: '',
      email: '',
      password: '',
    },
    step1Passed: false,
  },
  errorStripe: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    saveStep1Form: (
      state,
      action: PayloadAction<SignUpStep1Form & { selectedCoursesIds: number[] }>,
    ) => {
      state.signUp.step1Form = action.payload;
      state.signUp.step1Passed = true;
    },
    saveStep2Form: (state, action: PayloadAction<SignUpStep2Form>) => {
      state.signUp.step2Form = action.payload;
    },
    resetForm: state => {
      state.signUp.step1Form.selectedCoursesIds = [];
      state.signUp.step1Form.industry = 0;
      state.signUp.step1Form.state = 0;
      state.signUp.step2Form.first_name = '';
      state.signUp.step2Form.last_name = '';
      state.signUp.step2Form.state = '';
      state.signUp.step2Form.email = '';
      state.signUp.step2Form.password = '';
    },
    setErrorStripe: (state, action: PayloadAction<null | string>) => {
      state.errorStripe = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.user = action.payload.user_data;
      localStorage.setItem('access_token', action.payload.access_token);
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.access_token = action.payload.token.access_token;
      state.user = action.payload.data;
      localStorage.setItem('access_token', action.payload.token.access_token);
    });
    builder.addCase(signOut.fulfilled, state => {
      state.user = null;
      state.access_token = null;
      localStorage.removeItem('access_token');
    });
    builder.addCase(calcSignUpCourses.fulfilled, (state, action) => {
      state.signUp.calcData = action.payload;
    });
    builder.addCase(fetchSignUpOptions.fulfilled, (state, action) => {
      state.signUp.options = action.payload;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.user = action.payload.data;
    });
    builder.addCase(getProfile.rejected, state => {
      state.user = null;
      state.access_token = null;
      localStorage.removeItem('access_token');
    });
  },
});

export const { saveStep1Form, saveStep2Form, resetForm, setErrorStripe, setTheme } =
  userSlice.actions;

export default userSlice.reducer;
