import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import PopupPortal from '../../common/PopupPortal/PopupPortal';
import SVG from 'react-inlinesvg';
import Loader from '../../../assets/images/siteLogo/pas_loader_512x512.svg';
import LoaderLight from '../../../assets/images/siteLogo/pas_loader_512x512_L.svg';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';

const BackdropSpinner = () => {
  const isDarkTheme = useIsDarkTheme();
  return (
    <PopupPortal portalElementId={'Backdrop'}>
      <Backdrop
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
        open={true}
      >
        <SVG
          src={isDarkTheme ? LoaderLight : Loader}
          width={128}
          height="100%"
          title="Loader"
        />
      </Backdrop>
    </PopupPortal>
  );
};

export default BackdropSpinner;
