import { ToastContainer, Theme } from 'react-toastify';
import React from 'react';
import { useAppSelector } from '../../../hooks/global';

const Snackbar = () => {
  const themeFromRedux = useAppSelector(state => state.user.theme);

  return <ToastContainer theme={(themeFromRedux as Theme) || 'light'} />;
};

export default Snackbar;
