import React, { FC, ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import ModalSimple from '../ModalSimple/ModalSimple';

interface IConfig {
  title?: string | ReactElement;
  text?: string | ReactElement;
  buttons?: {
    left?: {
      title: string;
      onClick?: () => void;
    };
    right?: {
      title: string;
      onClick?: () => void;
    };
  };
}

let showAlert: (config: IConfig) => void;

const RemoteAlert: FC = () => {
  const [config, setConfig] = useState<IConfig | null>(null);

  showAlert = (newConfig: IConfig) => {
    setConfig(newConfig);
  };

  const handleClick = (handler?: () => void) => () => {
    setConfig(null);
    handler && handler();
  };

  if (!config) return null;
  const { title, text, buttons } = config;

  return (
    <ModalSimple open={true} onClose={() => setConfig(null)}>
      <div className="b-alert">
        <div className="b-alert__body">
          {title ? <div className="b-alert__title">{title}</div> : null}
          {text ? <div className="b-alert__text">{text}</div> : null}
        </div>
        {buttons ? (
          <div className="b-alert__footer">
            {buttons.left ? (
              <Button
                onClick={handleClick(buttons.left.onClick)}
                sx={{ marginRight: 1 }}
                className="b-btn -smallSize -transparent"
              >
                <span className="b-btn__text">{buttons.left.title}</span>
              </Button>
            ) : null}
            {buttons.right ? (
              <Button
                onClick={handleClick(buttons.right.onClick)}
                sx={{ marginRight: 1 }}
                className="b-btn -smallSize"
              >
                <span className="b-btn__text">{buttons.right.title}</span>
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </ModalSimple>
  );
};

export default RemoteAlert;
export { showAlert };
